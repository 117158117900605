<template>
  <div>
    <v-row no-gutters>
      <v-col v-show="showpanel" cols="12" :sm="banner" class="spaces">
        <div>
          <v-img
            :src="require('../assets/images/hospital-bed.png')"
            gradient="to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0.64) 100%"
            :height="windowHeight"
          >
            <div class="login_adverts">
              <!-- starts -->
              <v-carousel
                :height="windowHeight"
                :continuous="false"
                :cycle="cycle"
                :show-arrows="false"
                hide-delimiter-background
                delimiter-icon="mdi-minus"
              >
                <v-carousel-item v-for="(slide, i) in slides" :key="i">
                  <v-sheet color="transparent" height="100%" tile>
                    <v-row class="fill-height" align="center" justify="center">
                      <div class="side_adverts">
                        <h1>{{ slide.title }}</h1>
                        <p class="captions">{{ slide.caption }}</p>
                        <p>{{ slide.text }}</p>
                      </div>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
              <!-- emds -->
            </div>
          </v-img>
        </div>
      </v-col>
      <v-col cols="12" :sm="logon" class="spaces">
        <div class="login">
          <v-form @submit.prevent="go">
            <v-card flat outlined rounded="0">
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-img
                  :src="require('../assets/logo.png')"
                  height="35"
                  contain
                ></v-img>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-toolbar flat style="padding: 0px; margin: 0px">
                <v-spacer></v-spacer>
                <v-toolbar-title>Umohe Admin</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  label="email"
                  v-model="entry.email"
                ></v-text-field>
                <v-text-field
                  v-model="entry.password"
                  label="password"
                  :type="showCurrent ? 'text' : 'password'"
                  :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showCurrent = !showCurrent"
                ></v-text-field>
              </v-card-text>
              <v-card-actions style="padding: 0px 16px">
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="loginx"
                  depressed
                  block
                  :disabled="xlogin"
                >
                  login
                </v-btn>
              </v-card-actions>

              <v-card-actions>
                <v-btn
                  color="red"
                  @click.stop="forgotDialog = true"
                  :loading="forgotx"
                  text
                  block
                  :disabled="xforgot"
                >
                  forgotten?
                </v-btn>
                <ForgotDialog
                  :forgotDialog="forgotDialog"
                  @closeForgot="closeForgot"
                  @submitForgot="submitForgot"
                />
              </v-card-actions>
            </v-card>
          </v-form>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import ForgotDialog from "./../components/accounts/entry/forgotton";

export default {
  components: {
    ForgotDialog,
  },
  data() {
    return {
      showCurrent: false,
      colors: [
        "green",
        "secondary",
        "yellow darken-4",
        "red lighten-2",
        "orange darken-1",
      ],
      cycle: true,
      // slides: ["First", "Second", "Third", "Fourth", "Fifth"],
      slides: [
        {
          title: "easy healthcare",
          caption: "comprehensive services provided by medical persons",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, sunt cupiditate quod consequuntur laborum dolorum tempora ad quam labore libero ex sed nostrum tempore dolorem nisi obcaecati earum laudantium corrupti.",
        },
        {
          title: "quick healthcare",
          caption: "quick and convinient care at your convienient",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, sunt cupiditate quod consequuntur laborum dolorum tempora ad quam labore libero ex sed nostrum tempore dolorem nisi obcaecati earum laudantium corrupti.",
        },
        {
          title: "all healthcare resources",
          caption: "always getting the best familiar healthcare your desire",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, sunt cupiditate quod consequuntur laborum dolorum tempora ad quam labore libero ex sed nostrum tempore dolorem nisi obcaecati earum laudantium corrupti.",
        },
      ],

      forgotDialog: false,
      loginx: false,
      xlogin: false,
      forgotx: false,
      xforgot: false,
      registerx: false,
      xregister: false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      // showpanel: true,
      banner: 6,
      logon: 6,
      txt: "",
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ...",
      },
      entry: {
        email: null,
        password: null,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    showpanel: {
      get() {
        if (this.windowWidth <= 600) {
          return false;
        } else {
          return true;
        }
      },
      set(val) {
        return val;
      },
    },
  },
  watch: {
    windowWidth: function (value) {
      console.log(value);
      if (value <= 600) {
        console.log("less than");
        this.banner = 0;
        this.logon = 12;
        this.showpanel = false;
      } else {
        console.log("greater than");
        this.banner = 6;
        this.logon = 6;
        this.showpanel = true;
      }
    },
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    go() {
      this.snack.bar = true;
      this.loginx = true;
      this.xforgot = true;
      this.xregister = true;
      // let dat

      console.log("Entry:", this.entry);
      let self = this;
      Restful.entry
        .login(this.entry)
        .then((response) => {
          console.log(response);
          let name = response.data.name;
          let role = response.data.role;
          let token = response.data.token;
          let pix = response.data.pix;
          localStorage.setItem("user.role", role);

          if (role === "super" || role === "admin") {
            localStorage.setItem("user.token", token);
            localStorage.setItem("user.name", name);
            localStorage.setItem("user.pix", pix);
            localStorage.setItem("user.role", role);
            this.$router.push({
              name: "admin.dash",
            });
          } else {
            this.snack.text = "No Access";
          }
          this.loginx = false;
          this.xforgot = false;
          this.xregister = true;
        })
        .catch((error) => {
          console.log(error);
          if (error.response === undefined) {
            self.snack.bar = true;
            self.snack.color = "error";
            self.snack.text =
              "Please check internet connection or wait for a while and try logging in again.";
          } else {
            console.log(error.response.data);
            self.snack.bar = true;
            self.snack.color = "error";
            self.snack.text = error.response.data;
          }
          self.loginx = false;
          self.xforgot = false;
          self.xregister = true;
        });
    },
    closeForgot(p) {
      console.log(p);
      this.forgotDialog = p.state;
    },
    submitForgot(p) {
      this.snack.bar = true;
      this.xlogin = true;
      this.xforgot = true;

      this.forgotDialog = p.state;
      let self = this;
      Restful.entry
        .forgot(p.data)
        .then((response) => {
          this.snack.bar = true;
          console.log(response);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.xlogin = false;
          this.xforgot = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data);
          self.snack.bar = true;
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.xlogin = false;
          self.xforgot = false;
        });
    },
  },
};
</script>
