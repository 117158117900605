<template>
  <v-dialog v-model="forgotDialog" persistent max-width="400">
    <!-- hide-overlay
    fullscreen
    transition="dialog-bottom-transition" -->
    <v-card>
      <v-card-title>forgotten password</v-card-title>
      <v-card-text>
        <v-text-field
          label="submit your email"
          v-model.trim="email"
          @input="$v.email.$touch()"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.email.$invalid"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//   import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
  props: {
    forgotDialog: Boolean,
    myId: { type: Number, default: 0 },
  },
  mixins: [validationMixin],
  data() {
    return {
      responseMessage: null,
      email: null,
    };
  },
  validations: {
    email: { required, email },
  },
  created() {},
  methods: {
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeForgot", payload);
    },
    submit() {
      let email = this.email;
      let site = "admin";
      let da = { email, site };
      let payload = { id: this.myId, state: false, data: da };
      this.$emit("submitForgot", payload);
    },
  },
};
</script>
